import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import "./style.css"

const faqs = [
  {
    question: "Why should I choose nanoresume.com to build my resume?",
    answer:
      "nanoresume.com focuses on the things that drive a recruiter's decision: content and quality. We offer you a simple process for writing a high-quality resume, while targeting them to your chosen job positions and styling your resume without sacrificing its readability.",
  },
  {
    question: "How many different resumes can I create?",
    answer:
      "You can create an unlimited number of resumes – entirely for free.",
  },
  {
    question: "How many times can I download my resume?",
    answer:
      "You can download your resume an unlimited number of times – totally for free.",
  },
  {
    question: "Can I add a custom info block on my resume?",
    answer:
      "You can create your own section and customize it the way you want.",
  },
  {
    question: "Which information can I include on my resume?",
    answer:
      "You create your resume using personal information, experience, education, certificates, skills, languages, and summary.",
  },
  {
    question: "Can I change my resume's layout and move my content around?",
    answer:
      "You can definitely do that. Auto-positioning is available as well, it makes resume writing an effortless experience as well since you don't need to worry about the right positioning and spacing at all. However you can change your resume layout the way you want",
  },
  {
    question: "Can I download my resume as a .doc file?",
    answer:
      "At the moment, you can download your resume only in PDF format. The .doc file will be added soon.",
  },
  {
    question: "In which languages can I create my resume?",
    answer: "You can create your resume in any left to right language",
  },
]

export default function Faqs() {
  return (
    <Layout>
      <Seo title="Faqs" />
      <div className="home-page-container">
        <div className="faqs-section-heading-container">
          <h1>Have questions? We have answers.</h1>
        </div>
        <div className="faqs-list">
          {faqs.map(faq => (
            <Faq {...faq} />
          ))}
        </div>
        <div className="faqs-section-view-more">
          <p>
            For more questions and answers visit our{" "}
            <Link to="/help">Help Center</Link>
          </p>
        </div>
      </div>
    </Layout>
  )
}

function Faq({ question, answer }) {
  return (
    <div className="faq">
      <h2 className="question">{question}</h2>
      <p className="answer">{answer}</p>
    </div>
  )
}
